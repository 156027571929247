import { useState } from "react";
import { useNavigate } from "react-router-dom";

import fileService from "../services/files";

import MetadataLabel from "./MetadataLabel";
import MetadataInput from "./MetadataInput";

import "./EditMultipleView.css";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { stringToSelect } from "../services/metadata";

/**
 * Returns a function that can be passed as setValue, but sets a composite state.
 */
const setValueByKey = (setValue, key, oldValues) => (value) =>
  setValue(
    oldValues.map((currentValue, currentKey) =>
      key === currentKey ? value : currentValue
    )
  );

const setValueForColumn = (setValue, oldValues) => (value) =>
  setValue(oldValues.map(() => value));

const EditMultipleView = ({
  delivery,
  updateLastModified,
  files,
  setMultipleMetadata,
  user,
  creator,
  notify,
  toggleLoading,
  uncheckFiles,
}) => {
  // Here, state is an array of corresponding state for each file
  const [title, setTitle] = useState(files.map((file) => file.metadata.title));
  const [description, setDescription] = useState(
    files.map((file) => file.metadata.description)
  );
  const [source, setSource] = useState(
    files.map((file) => file.metadata.source)
  );
  const [language, setLanguage] = useState(
    files.map((file) => file.metadata.language)
  );

  // Here we also apply stringToSelect as in MetadataEntry
  const [rights, setRights] = useState(
    files.map((file) => stringToSelect(file.metadata.rights))
  );
  const [subjects, setSubjects] = useState(
    files.map((file) => file.metadata.subjects.map(stringToSelect))
  );
  const [dates, setDates] = useState(
    files.map((file) => file.metadata.dates.map(stringToSelect))
  );
  const [types] = useState(
    files.map((file) => file.metadata.types.map(stringToSelect))
  );
  const [coverages, setCoverages] = useState(
    files.map((file) => file.metadata.coverages.map(stringToSelect))
  );

  const [creators, setCreators] = useState(
    files.map((file) =>
      file.metadata.creators.length
        ? file.metadata.creators.map(stringToSelect)
        : [creator.name].map(stringToSelect)
    )
  );
  const [publishers, setPublishers] = useState(
    files.map((file) => file.metadata.publishers.map(stringToSelect))
  );
  const [contributors, setContributors] = useState(
    files.map((file) => file.metadata.contributors.map(stringToSelect))
  );

  const navigate = useNavigate();

  const selectToString = (select) => select.value;

  const formStateToFile = (file, index) => ({
    ...file,
    metadata: {
      ...file.metadata,
      title: title[index],
      description: description[index],
      format: file.metadata.format,
      source: source[index],
      language: language[index],
      rights: selectToString(rights[index]),
      subjects: subjects[index].map(selectToString),
      dates: dates[index].map(selectToString),
      types: types[index].map(selectToString),
      coverages: coverages[index].map(selectToString),
      creators: creators[index].map(selectToString),
      publishers: publishers[index].map(selectToString),
      contributors: contributors[index].map(selectToString),
    },
  });

  const handleNewMetadata = async () => {
    const newFiles = files.map(formStateToFile);

    try {
      toggleLoading(true);
      const apiMetadatas = await fileService.submitFiles(
        newFiles,
        delivery,
        user,
        creator.id
      );

      setMultipleMetadata(newFiles, apiMetadatas);
      updateLastModified(delivery.deliveryId);
      notify("Dina ändringar är sparade.", "info");
    } catch (e) {
      notify("Något gick fel.");
    } finally {
      toggleLoading(false);
    }
  };

  const handleBack = () => {
    navigate(`/deliveries/${delivery.deliveryId}`);
    uncheckFiles();
  };

  return (
    <div className="d-flex flex-column h-100">
      <Table id="edit-multiple-table" striped responsive>
        <thead>
          <tr>
            <td></td>
            <td>
              <MetadataLabel id="title" required={true} />
            </td>
            <td>
              <MetadataLabel id="description" />
            </td>
            <td>
              <MetadataLabel id="subjects" />
            </td>
            <td>
              <MetadataLabel id="dates" required={true} />
            </td>
            <td>
              <MetadataLabel id="rights" required={true} />
            </td>
            <td>
              <MetadataLabel id="language" />
            </td>
            <td>
              <MetadataLabel id="creators" />
            </td>
            <td>
              <MetadataLabel id="contributors" />
            </td>
            <td>
              <MetadataLabel id="source" />
            </td>
            <td>
              <MetadataLabel id="coverages" />
            </td>
            <td>
              <MetadataLabel id="publishers" />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Redigera hela kolumnen</strong>
            </td>
            <td>
              <MetadataInput
                id="title"
                defaultValue=""
                setValue={setValueForColumn(setTitle, title)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="description"
                defaultValue=""
                setValue={setValueForColumn(setDescription, description)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="subjects"
                defaultValue=""
                setValue={setValueForColumn(setSubjects, subjects)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="dates"
                defaultValue=""
                setValue={setValueForColumn(setDates, dates)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="rights"
                defaultValue=""
                setValue={setValueForColumn(setRights, rights)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="language"
                defaultValue=""
                setValue={setValueForColumn(setLanguage, language)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="creators"
                defaultValue=""
                setValue={setValueForColumn(setCreators, creators)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="contributors"
                defaultValue=""
                setValue={setValueForColumn(setContributors, contributors)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="source"
                defaultValue=""
                setValue={setValueForColumn(setSource, source)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="coverages"
                defaultValue=""
                setValue={setValueForColumn(setCoverages, coverages)}
                isColumnEdit
              />
            </td>
            <td>
              <MetadataInput
                id="publishers"
                defaultValue=""
                setValue={setValueForColumn(setPublishers, publishers)}
                isColumnEdit
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={file.id}>
              <td>{file.name}</td>
              <td>
                <MetadataInput
                  id="title"
                  value={title[index]}
                  setValue={setValueByKey(setTitle, index, title)}
                />
              </td>
              <td>
                <MetadataInput
                  id="description"
                  value={description[index]}
                  setValue={setValueByKey(setDescription, index, description)}
                />
              </td>
              <td>
                <MetadataInput
                  id="subjects"
                  value={subjects[index]}
                  setValue={setValueByKey(setSubjects, index, subjects)}
                />
              </td>
              <td>
                <MetadataInput
                  id="dates"
                  value={dates[index]}
                  setValue={setValueByKey(setDates, index, dates)}
                />
              </td>
              <td>
                <MetadataInput
                  id="rights"
                  value={rights[index]}
                  setValue={setValueByKey(setRights, index, rights)}
                />
              </td>
              <td>
                <MetadataInput
                  id="language"
                  value={language[index]}
                  setValue={setValueByKey(setLanguage, index, language)}
                />
              </td>
              <td>
                <MetadataInput
                  id="creators"
                  value={creators[index]}
                  setValue={setValueByKey(setCreators, index, creators)}
                />
              </td>
              <td>
                <MetadataInput
                  id="contributors"
                  value={contributors[index]}
                  setValue={setValueByKey(setContributors, index, contributors)}
                />
              </td>
              <td>
                <MetadataInput
                  id="source"
                  value={source[index]}
                  setValue={setValueByKey(setSource, index, source)}
                />
              </td>
              <td>
                <MetadataInput
                  id="coverages"
                  value={coverages[index]}
                  setValue={setValueByKey(setCoverages, index, coverages)}
                />
              </td>
              <td>
                <MetadataInput
                  id="publishers"
                  value={publishers[index]}
                  setValue={setValueByKey(setPublishers, index, publishers)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between">
        <Button
          className="my-3"
          type="button"
          variant="secondary"
          onClick={handleBack}
        >
          Tillbaka
        </Button>
        <Button
          className="my-3"
          type="button"
          variant="primary"
          onClick={handleNewMetadata}
        >
          Spara
        </Button>
      </div>
    </div>
  );
};

export default EditMultipleView;
