import axios from "axios";

const baseUrl = "/api/users/members";

const getAllByCreator = async (creatorId) => {
  const response = await axios.get("/api/archives/members", {
    params: { creatorId },
  });
  const data = response.data;

  return data;
};

const getAllByUser = async (userId) => {
  const response = await axios.get(baseUrl, {
    params: { userId },
  });
  const data = response.data;

  return data;
};

const create = async (apiMembership) => {
  const response = await axios.post("/api/archives/members", null, {
    params: {
      creatorId: apiMembership.creatorId,
      userId: apiMembership.userId,
      contact: apiMembership.contactPerson,
    },
  });
  const data = response.data;

  return data;
};

const update = async (newMembership) => {
  const apiMembership = {
    ...newMembership,
    endDate: new Date(newMembership.endDate).toISOString(),
  };

  const response = await axios.patch(
    "/api/archives/members",
    JSON.stringify(apiMembership, null, 1),
    {
      params: { id: apiMembership.id },
      headers: { "Content-Type": "application/json" },
    }
  );
  const data = response.data;

  return data;
};

const membershipService = { getAllByCreator, getAllByUser, create, update };
export default membershipService;
