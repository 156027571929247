import { InfoCircle } from "react-bootstrap-icons";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const InfoIcon = ({ description }) => {
  return description ? (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip>{description}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <InfoCircle role="img" className="ml-1" ref={ref} {...triggerHandler} />
      )}
    </OverlayTrigger>
  ) : null;
};

export default InfoIcon;
