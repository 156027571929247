import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { Link, useLocation } from "react-router-dom";

const PortalHeader = ({ user, archiveName, creatorName, handleLogout }) => {
  const userName = user?.fullName;
  const dataSupplied = archiveName && creatorName && userName;
  const loggedInAsAdmin = user?.roles?.includes("ROLE_ADMIN");

  const location = useLocation();

  return (
    <header>
      <Container>
        <Link
          to="/deliveries"
          style={{ color: "white", textDecoration: "none" }}
        >
          <h1>E-ARKIVPORTALEN</h1>
        </Link>
        {user && (
          <div id="nav">
            <span>
              {dataSupplied
                ? `${archiveName}, ${creatorName}, ${userName}`
                : "Laddar ..."}
            </span>
            {loggedInAsAdmin &&
              (location.pathname.startsWith("/admin") ? (
                <Link to="/deliveries">
                  <Button>Inleveranser</Button>
                </Link>
              ) : (
                <Link to="/admin">
                  <Button>Administrera</Button>
                </Link>
              ))}
            <Button onClick={handleLogout}>Logga ut</Button>
          </div>
        )}
      </Container>
    </header>
  );
};

export default PortalHeader;
