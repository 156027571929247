const types = [
  { value: "avtal", label: "avtal" },
  { value: "bloggar", label: "bloggar" },
  { value: "bokslut", label: "bokslut" },
  { value: "cirkulär", label: "cirkulär" },
  { value: "dagbok", label: "dagbok" },
  { value: "dagordning", label: "dagordning" },
  { value: "databaser", label: "databaser" },
  { value: "datorstödd design", label: "datorstödd design" },
  { value: "diarium", label: "diarium" },
  { value: "fotografier", label: "fotografier" },
  { value: "föremål", label: "föremål" },
  { value: "gästbok", label: "gästbok" },
  { value: "historiker", label: "historiker" },
  { value: "kallelse", label: "kallelse" },
  { value: "kartor", label: "kartor" },
  { value: "korrespondens", label: "korrespondens" },
  { value: "ljudfiler", label: "ljudfiler" },
  { value: "mikrofilmer", label: "mikrofilmer" },
  { value: "målningar", label: "målningar" },
  { value: "negativ", label: "negativ" },
  { value: "ospecificerad", label: "ospecificerad" },
  { value: "protokoll", label: "protokoll" },
  { value: "rapporter", label: "rapporter" },
  { value: "register", label: "register" },
  { value: "revisionsberättelser", label: "revisionsberättelser" },
  { value: "räkenskapsböcker", label: "räkenskapsböcker" },
  { value: "sociala medier", label: "sociala medier" },
  { value: "stadgar", label: "stadgar" },
  { value: "statistik", label: "statistik" },
  { value: "teckningar", label: "teckningar" },
  { value: "tekniska ritningar", label: "tekniska ritningar" },
  { value: "trycksaker", label: "trycksaker" },
  { value: "urklippssamlingar", label: "urklippssamlingar" },
  { value: "vektorgrafik", label: "vektorgrafik" },
  { value: "verksamhetsberättelser", label: "verksamhetsberättelser" },
  { value: "video", label: "video" },
  { value: "webbplatser", label: "webbplatser" },
  { value: "årsredovisningar", label: "årsredovisningar" },
];

const rights = [
  { value: "Arkiv", label: "Arkiv" },
  { value: "Publikt", label: "Publikt" },
];

const coverages = [
  { value: "Blekinge län", label: "Blekinge län" },
  { value: "Dalarnas län", label: "Dalarnas län" },
  { value: "Gotlands län", label: "Gotlands län" },
  { value: "Gävleborgs län", label: "Gävleborgs län" },
  { value: "Hallands län", label: "Hallands län" },
  { value: "Jämtlands län", label: "Jämtlands län" },
  { value: "Jönköpings län", label: "Jönköpings län" },
  { value: "Kalmar län", label: "Kalmar län" },
  { value: "Kronobergs län", label: "Kronobergs län" },
  { value: "Norrbottens län", label: "Norrbottens län" },
  { value: "ne län", label: "ne län" },
  { value: "Stockholms län", label: "Stockholms län" },
  { value: "Södermanlands län", label: "Södermanlands län" },
  { value: "Uppsala län", label: "Uppsala län" },
  { value: "Värmlands län", label: "Värmlands län" },
  { value: "Västerbottens län", label: "Västerbottens län" },
  { value: "Västernorrlands län", label: "Västernorrlands län" },
  { value: "Västmanlands län", label: "Västmanlands län" },
  { value: "Götalands län", label: "Götalands län" },
  { value: "Örebro län", label: "Örebro län" },
  { value: "Östergötlands län", label: "Östergötlands län" },
];

const booleans = [
  { value: true, label: "Ja" },
  { value: false, label: "Nej" },
];

export const fields = {
  title: {
    name: "Titel",
    description:
      'En kort beskrivning av innehållet i filen, gärna med förtydligande av datum och plats. Exempel: "Midsommarfirande, Torö, Nynäshamn 2021", "Protokoll, årsmöte, Trosa 1993".',
  },
  description: {
    name: "Beskrivning",
    description:
      "Här beskriver du mer fritt och utförligt vilka och/eller vad ett fotografi föreställer, vad en text handlar om, och så vidare.",
  },
  source: {
    name: "Källa",
    description:
      "En referens till en källa eller arkivbildare som den aktuella filen representerar eller härstammar ifrån Exempel: ett fotoalbum, ett gårdsarkiv, en person, ett företag, en förening, och så vidare. Här kan också en eventuell annan arkivbildare anges.",
  },
  language: {
    name: "Språk",
    description:
      'Välj "Svenska" eller "Annat" i de fall innehållet utgörs av ett annat språk. Det språket kan uppges i fältet "Beskrivning".',
  },
  rights: {
    name: "Rättigheter",
    description:
      "Här bestämmer du om filen ska vara tillgänglig för alla, eller om den ska bevaras som i ett traditionellt arkiv där intresserade har möjlighet att begära ut den.",
    options: rights,
  },
  subjects: {
    name: "Handlingstyp",
    description:
      "Typ av handling eller format som beskriver filen och gör den sökbar i e-arkivet.",
    multiple: true,
    options: types,
  },
  dates: {
    name: "Datum",
    description:
      "Ett datum knutet till den händelse som rör innehållet i handlingen. Exempel: när ett fotografi togs, när ett protokoll uppfördes, eller datum för ett möte. Datum ska anges enligt formatet ÅÅÅÅ-MM-DD.",
    multiple: true,
  },
  coverages: {
    name: "Geografi",
    description: "Omfattning eller utsträckning hos resursens innehåll.",
    multiple: true,
    options: coverages,
  },
  creators: {
    name: "Skapare",
    description:
      "Namnet på den person eller organisation som i första hand är ansvarig för filens intellektuella innehåll. I de flesta fall din förening, men i de fall någon annan är ansvarig uppger du den personen eller organisationen här.",
    multiple: true,
  },
  publishers: {
    name: "Utgivare",
    description:
      "Namn på person eller organisation som är ansvarig för att ha gjort filens innehåll, eller själva filen, tillgänglig.",
    multiple: true,
  },
  contributors: {
    name: "Medskapare",
    description:
      'Namnet på upphovsperson för aktuell fil. Exempel: författare till en text, fotograf till ett fotografi, och så vidare. Ange gärna både titel och efternamn, "Fotograf Annika Larsson", "Projektledare Ulf Nilsson".',
    multiple: true,
  },
  name: { name: "Namn" },
  archiveCreatorNr: { name: "Arkivbildarnummer", disabled: true },
  orgNr: { name: "Organisationsnummer" },
  street: { name: "Gatuadress" },
  zip: { name: "Postkod" },
  city: { name: "Postort" },
  telephone: { name: "Telefon" },
  email: { name: "E-postadress" },
  password: { name: "Lösenord" },
  firstName: { name: "Förnamn" },
  lastName: { name: "Efternamn" },
  startDate: { name: "Startdatum", disabled: true },
  endDate: { name: "Slutdatum" },
  contact: { name: "Kontaktperson", options: booleans },
  format: { name: "Format", disabled: true },
};
