import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";

const MembershipTable = ({ id, memberships }) => {
  return (
    <>
      <h3>Medlemmar</h3>
      <div id="file-table" className="table-responsive ">
        <Table striped>
          <thead>
            <tr>
              <td>Användare</td>
              <td>ID</td>
              <td>Startdatum</td>
              <td>Slutdatum</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {memberships.map((membership) => (
              <tr key={membership.id}>
                <td>{membership.userName}</td>
                <td>{membership.userId}</td>
                <td>{membership.startDate}</td>
                <td>{membership.endDate}</td>
                <td>
                  <Link
                    to={`/admin/users/${membership.userId}/memberships/${membership.id}/creators/${id}`}
                  >
                    <Button type="button">Redigera</Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Link to={`/admin/users/create/memberships/create/creators/${id}`}>
        <Button className="float-right">Lägg till ny användare</Button>
      </Link>
    </>
  );
};

export default MembershipTable;
