import axios from "axios";

import metadataService from "../services/metadata";

const baseUrl = "/api/deliveries/files";

const create = async (deliveryId, files, userId, creatorId) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file.file, file.name);
  });

  // New metadata, all ids should be null
  const apiMetadatas = files.map((file) =>
    metadataService.formatMetadata(
      file.name,
      metadataService.stripMetadata(file.metadata)
    )
  );

  // Back-end breaks on unformatted JSON
  formData.append("data", JSON.stringify(apiMetadatas, null, 1));

  const response = await axios.post(
    `/api/deliveries/${deliveryId}/files`,
    formData,
    {
      params: { userId, creatorId },
    }
  );
  return response.data;
};

const update = async (deliveryId, files) => {
  const apiMetadatas = files.map((file) =>
    metadataService.formatMetadata(
      file.name,
      metadataService.stripMetadata(file.metadata)
    )
  );

  // Back-end breaks on unformatted JSON
  const response = await axios.patch(
    baseUrl,
    JSON.stringify(apiMetadatas, null, 1),
    {
      params: { id: deliveryId },
      headers: { "Content-Type": "application/json;charset=UTF-8" },
    }
  );

  return response.data;
};

const submitFiles = async (newFiles, delivery, user, creatorId) => {
  const storedFiles = newFiles.filter((file) => file.stored);
  const storedApiMetadatas = storedFiles.length
    ? await fileService.update(delivery.deliveryId, storedFiles)
    : [];

  const unstoredFiles = newFiles.filter((file) => !file.stored);
  const unstoredApiMetadatas = unstoredFiles.length
    ? (
        await fileService.create(
          delivery.deliveryId,
          unstoredFiles,
          user.id,
          creatorId
        )
      ).map(injectId)
    : [];

  const apiMetadatas = storedApiMetadatas.concat(unstoredApiMetadatas);

  return apiMetadatas;
};

const removeIfExists = async (deliveryId, files) => {
  // id should be as given from the server when fetching the
  // delivery. An apiFile has more properties, but they must not
  // be sent here according to spec.
  const apiFiles = files.map((file) => ({
    id: file.metadata.fileId ?? file.id,
  }));

  let response = { data: null };
  try {
    response = await axios.delete(`${baseUrl}/${deliveryId}`, {
      data: JSON.stringify(apiFiles, null, 1),
    });
  } catch (error) {
    const fileMissing =
      error.response.status === 400 &&
      (!error.response.data.match(/input string/i) ||
        !error.response.data.match(/IOException/i));
    if (!fileMissing) {
      // Unknown error
      throw error;
    }

    // The file doesn't seem to exist, carry on
  }

  return response.data;
};

const injectId = (apiFile) => ({ ...apiFile.metadata, fileId: apiFile.id });

const fileService = { create, update, submitFiles, removeIfExists, injectId };

export default fileService;
