import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import creatorService from "../services/creators";

import MetadataFormGroup from "./MetadataFormGroup";
import MembershipTable from "./MembershipTable";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const CreatorView = ({
  creators,
  onSubmit,
  onMembershipsUpdate,
  memberships,
}) => {
  const creatorId = useParams()["id"];
  const creator = creators.find((creator) => String(creator.id) === creatorId);
  const nextArchiveCreatorNr =
    Math.max(...creators.map((creator) => creator.archiveCreatorNr)) + 1;

  useEffect(() => {
    onMembershipsUpdate(creatorId);
  }, [creatorId, onMembershipsUpdate]);

  const alreadyStored = creatorId !== "create";

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [archiveCreatorNr, setArchiveCreatorNr] =
    useState(nextArchiveCreatorNr);
  const [orgNr, setOrgNr] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    loadCreator(creator);
  }, [creator]);

  const loadCreator = (creator) => {
    if (creator) {
      setName(creator.associationName);
      setArchiveCreatorNr(creator.archiveCreatorNr);
      setOrgNr(creator.orgNr);
      setStreet(creator.address.street);
      setZip(creator.address.zip);
      setCity(creator.address.city);
      setTelephone(creator.telephone);
      setEmail(creator.email);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const apiCreator = {
      id: alreadyStored ? creatorId : null,
      archiveCreatorNr,
      orgNr,
      associationName: name,
      email,
      telephone,
      address: {
        id: alreadyStored ? creator.address.id : null,
        street,
        city,
        zip,
      },
    };

    let newCreator;
    if (alreadyStored) {
      newCreator = await creatorService.update(apiCreator);
    } else {
      newCreator = await creatorService.create(apiCreator);
    }

    onSubmit(creatorId, alreadyStored, newCreator);

    navigate("/admin/creators");
  };

  return (
    <Container>
      <h2>
        {alreadyStored
          ? `Redigera ${creator?.associationName}`
          : "Ny arkivbildare"}
      </h2>
      <Form aria-label="form" onSubmit={handleSubmit}>
        <MetadataFormGroup
          id="name"
          value={name}
          setValue={setName}
          required={true}
        />
        <MetadataFormGroup
          id="archiveCreatorNr"
          value={archiveCreatorNr}
          setValue={setArchiveCreatorNr}
          required={true}
        />
        <MetadataFormGroup
          id="orgNr"
          value={orgNr}
          setValue={setOrgNr}
          required={true}
        />
        <MetadataFormGroup
          id="street"
          value={street}
          setValue={setStreet}
          required={true}
        />
        <MetadataFormGroup
          id="zip"
          value={zip}
          setValue={setZip}
          required={true}
        />
        <MetadataFormGroup
          id="city"
          value={city}
          setValue={setCity}
          required={true}
        />
        <MetadataFormGroup
          id="telephone"
          value={telephone}
          setValue={setTelephone}
          required={true}
        />
        <MetadataFormGroup
          id="email"
          value={email}
          setValue={setEmail}
          required={true}
        />
        {alreadyStored && (
          <MembershipTable
            selection={"creators"}
            id={creatorId}
            memberships={memberships}
          />
        )}
        <Button type="submit">Spara och gå tillbaka</Button>
      </Form>
    </Container>
  );
};

export default CreatorView;
