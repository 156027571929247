import axios from "axios";

const baseUrl = "/api/users";

const getAll = async () => {
  const response = await axios.get(`${baseUrl}/all`);
  const apiUsers = response.data;

  return apiUsers.map((apiUser) => ({
    ...apiUser,
    fullName: `${apiUser.firstName} ${apiUser.lastName}`,
  }));
};

const getCurrent = async () => {
  const response = await axios.get(`${baseUrl}/session`);
  const user = {
    ...response.data,
    id: response.data.userId,
  };

  return user;
};

const create = async (creatorId, apiUser) => {
  const response = await axios.post(baseUrl, JSON.stringify(apiUser, null, 1), {
    params: { creatorId },
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
};

const update = async (apiUser) => {
  const response = await axios.patch(
    baseUrl,
    JSON.stringify(apiUser, null, 1),
    { headers: { "Content-Type": "application/json" } }
  );
  const data = response.data;

  return data;
};

const userService = { getAll, getCurrent, create, update };
export default userService;
