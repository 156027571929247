import axios from "axios";

const baseUrl = "/api/archives/creators";

const getAll = async () => {
  const response = await axios.get(baseUrl);
  const apiCreators = response.data;

  // Response follows "apiCreator" format.
  // Map keys to the "apiArchiveCreator" format for compatibility.
  return apiCreators.map((apiCreator) => ({
    ...apiCreator,
    creatorId: apiCreator.id,
    creatorName: apiCreator.associationName,
    creatorNumber: apiCreator.archiveCreatorNr,
  }));
};

const getAllByUserId = async (userId) => {
  const response = await axios.get(`/api/users/${userId}/archiveCreators`);
  const data = response.data;

  // An array of "apiArchiveCreators"
  return data;
};

const create = async (apiCreator) => {
  const response = await axios.post(
    baseUrl,
    JSON.stringify(apiCreator, null, 1),
    { headers: { "Content-Type": "application/json" } }
  );
  const data = response.data;

  return data;
};

const update = async (apiCreator) => {
  const response = await axios.patch(
    baseUrl,
    JSON.stringify(apiCreator, null, 1),
    { headers: { "Content-Type": "application/json" } }
  );
  const data = response.data;

  return data;
};

const creatorService = { getAll, getAllByUserId, create, update };
export default creatorService;
