import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import userService from "../services/users";
import membershipService from "../services/memberships";

import MetadataFormGroup from "./MetadataFormGroup";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const UserView = ({ users, onSubmit }) => {
  const userId = useParams()["userId"];
  const user = users.find((user) => String(user.id) === userId);

  const creatorId = useParams()["creatorId"];

  const membershipId = useParams()["membershipId"];
  const alreadyStored = userId !== "create";
  const editingMembership = !!membershipId;
  const editingUser = !editingMembership || !alreadyStored;

  useEffect(() => {
    membershipService.getAllByUser(userId).then((membershipData) => {
      loadMembership(
        membershipData.find(
          (membership) => String(membership.id) === membershipId
        )
      );
    });
  }, [userId, membershipId]);

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contact, setContact] = useState("");

  const [collapsed, setCollapsed] = useState(false);
  const [selectedUser, setSelectedUser] = useState(users[0]?.id);

  useEffect(() => {
    loadUser(user);
  }, [user]);

  const loadUser = (user) => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setStreet(user.address.street);
      setZip(user.address.zip);
      setCity(user.address.city);
      setTelephone(user.phone);
      setEmail(user.email);
    }
  };

  const loadMembership = (membership) => {
    if (membership) {
      setStartDate(membership.startDate);
      setEndDate(membership.endDate);
      setContact(membership.contactPerson);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newUserId = collapsed ? selectedUser : alreadyStored ? userId : null;

    let newUser = {
      id: newUserId,
      enabled: true,
      firstName,
      lastName,
      email,
      password,
      phone: telephone,
      address: {
        id: alreadyStored ? user.address.id : null,
        street,
        city,
        zip,
      },
    };

    let newMembership = {
      id: alreadyStored ? membershipId : null,
      startDate,
      endDate,
      contactPerson: contact.value,
      creatorId,
      userId: newUserId,
    };

    if (!editingMembership) {
      // Case 0. Only editing existing user
      newUser = await userService.update(newUser);
      newMembership = null;
    } else if (editingUser && editingMembership && newUser.id) {
      // Case 1. Add membership to existing user
      // Functionality doesn't exist yet, missing dropdown in interface
      newUser = null;
      newMembership = await membershipService.create(newMembership);
    } else if (editingUser && editingMembership && !newUser.id) {
      // Case 2. Add membership to new user
      // Membership created back-end when user is created
      newUser = await userService.create(creatorId, newUser);

      // Contents of newUser subject to change, but should somehow
      // contain membershipId
      const membershipId = newUser.membershipId;

      newMembership = await membershipService.update({
        ...newMembership,
        id: membershipId,
      });
    } else if (!editingUser) {
      // Case 3. Only edit existing membership
      newUser = null;
      newMembership = await membershipService.update(newMembership);
    } else {
      throw new Error("Matched no case in UserView.");
    }

    onSubmit(userId, alreadyStored, newUser, newMembership);

    navigate("/admin/users");
  };

  const handleSelectedUserChange = ({ target }) => {
    setSelectedUser(target.value);
  };

  return (
    <Container>
      <h2>
        {alreadyStored
          ? `Redigera ${user.firstName} ${user.lastName}`
          : "Ny användare"}
      </h2>
      <Form aria-label="form" onSubmit={handleSubmit}>
        {editingUser && // First, are we showing the user form at all?
          (editingMembership && collapsed ? ( // If we are, collapsed or normal?
            <>
              <select
                name="creator"
                onChange={handleSelectedUserChange}
                value={selectedUser}
                className="d-flex mb-1"
              >
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
              </select>
              <Button onClick={() => setCollapsed(!collapsed)}>
                Helt ny användare
              </Button>
            </>
          ) : (
            <>
              <MetadataFormGroup
                id="firstName"
                value={firstName}
                setValue={setFirstName}
                required={true}
              />
              {editingMembership && ( // Check again to hide this button sometimes
                <Button onClick={() => setCollapsed(!collapsed)}>
                  Existerande användare
                </Button>
              )}
              <MetadataFormGroup
                id="lastName"
                value={lastName}
                setValue={setLastName}
                required={true}
              />
              <MetadataFormGroup
                id="street"
                value={street}
                setValue={setStreet}
              />
              <MetadataFormGroup id="zip" value={zip} setValue={setZip} />
              <MetadataFormGroup id="city" value={city} setValue={setCity} />
              <MetadataFormGroup
                id="telephone"
                value={telephone}
                setValue={setTelephone}
              />
              <MetadataFormGroup
                id="email"
                value={email}
                setValue={setEmail}
                required={true}
              />
              <MetadataFormGroup
                id="password"
                value={password}
                setValue={setPassword}
                required={true}
              />
            </>
          ))}

        {editingMembership && (
          <>
            <h3>Medlemskap</h3>
            <MetadataFormGroup
              id="startDate"
              value={startDate}
              setValue={setStartDate}
            />
            <MetadataFormGroup
              id="endDate"
              value={endDate}
              setValue={setEndDate}
              required={true}
            />
            <MetadataFormGroup
              id="contact"
              value={contact}
              setValue={setContact}
              required={true}
            />
          </>
        )}
        <Button type="submit">Spara och gå tillbaka</Button>
      </Form>
    </Container>
  );
};

export default UserView;
