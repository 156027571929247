import { useState } from "react";

import loginService from "../services/login";

import MetadataFormGroup from "./MetadataFormGroup";

import { Link } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const PasswordView = ({ notify }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await loginService.requestPassword(email);

      notify(
        "Ett nytt lösenord har skickats till den uppgivna adressen.",
        "info"
      );
    } catch (error) {
      notify(
        "Misslyckades begära nytt lösenord. Kontrollera e-postadressen och försök igen.",
        "danger"
      );
    }

    setEmail("");
  };

  return (
    <div className="center">
      <Form onSubmit={handleSubmit}>
        <MetadataFormGroup
          id="email"
          value={email}
          setValue={setEmail}
          required={true}
        />
        <div className="d-flex justify-content-between mt-2">
          <Link to="/login">
            <Button>Tillbaka</Button>
          </Link>
          <Link to="/login">
            <Button onClick={handleSubmit}>Begär nytt lösenord</Button>
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default PasswordView;
