import React, { useState, useEffect, useCallback } from "react";
import { formatISO } from "date-fns";
import { SpinnerComponent } from "react-element-spinner";

import userService from "./services/users";
import archiveService from "./services/archives";
import loginService from "./services/login";
import deliveryService, { sortDeliveries } from "./services/deliveries";
import creatorService from "./services/creators";
import metadataService from "./services/metadata";

import PortalHeader from "./components/PortalHeader";
import DeliveryView from "./components/DeliveryView";
import Notification from "./components/Notification";
import FileUpload from "./components/FileUpload";
import Login from "./components/Login";
import PasswordView from "./components/PasswordView";
import MetadataEntry from "./components/MetadataEntry";
import EditMultipleView from "./components/EditMultipleView";
import AdminRouter from "./components/AdminRouter";

import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useMatch,
} from "react-router-dom";

import Container from "react-bootstrap/Container";

import "./App.css";

const App = () => {
  const [user, setUser] = useState(null);
  const [creators, setCreators] = useState([]);
  const [deliveries, setDeliveries] = useState([]);

  // Array of {file, id, name, checked, metadata}
  // This represents the files of the current delivery
  const [files, setFiles] = useState([]);

  // State for Notification
  const [message, setMessage] = useState("");
  const [level, setLevel] = useState(null);

  // Archive data
  const [archiveName, setArchiveName] = useState(null);
  const [country, setCountry] = useState(null);
  const [archiveCode, setArchiveCode] = useState(null);

  // Current creator
  const [creatorName, setCreatorName] = useState(null);
  const [creatorNumber, setCreatorNumber] = useState(null);
  const [creatorId, setCreatorId] = useState("");

  const [loading, setLoading] = useState(false);

  const toggleLoading = (loading) => setLoading(loading);

  const navigate = useNavigate();

  const logoutLocally = useCallback(() => {
    window.localStorage.removeItem("user");
    setUser(null);
    navigate("/");
    toggleLoading(false);
    notify("Du har loggats ut.", "info");
  }, [navigate]);

  useEffect(() => {
    // Desired authentication scheme unclear,
    // review later
    const jsonUser = window.localStorage.getItem("user");
    if (jsonUser) {
      userService
        .getCurrent()
        .then(setUser)
        .catch((error) => {
          if (error.response?.status === 401) {
            logoutLocally();
          } else {
            // Do not swallow unknown error
            throw error;
          }
        });
    }
  }, [logoutLocally]);

  useEffect(() => {
    // Requires authentication
    if (user) {
      const loggedInAsAdmin = user.roles?.includes("ROLE_ADMIN");
      if (loggedInAsAdmin) {
        creatorService.getAll().then((creators) => {
          setCreators(creators);
          setCurrentCreator(creators[0]);
        });
      } else {
        creatorService.getAllByUserId(user.id).then((creators) => {
          setCreators(creators);
          setCurrentCreator(creators[0]);
        });
      }
    }
  }, [user]);

  const setCurrentCreator = (creator) => {
    setCreatorId(creator.creatorId);
    setCreatorName(creator.creatorName);
    setCreatorNumber(creator.creatorNumber);
  };

  useEffect(() => {
    // Requires authentication
    if (user && creatorId) {
      archiveService.getCurrent(user.id, creatorId).then((archiveData) => {
        // Setting ID late caused tests to fail because
        // the tests only waited on the visible parts
        setArchiveCode(archiveData.archiveCode);
        setCountry(archiveData.country);
        setArchiveName(archiveData.archiveName);
      });
    }
  }, [user, creatorId]);

  useEffect(() => {
    // Doesn't actually require authentication yet,
    // but should
    if (creatorId) {
      deliveryService
        .getAll(creatorId)
        .then((deliveryData) => setDeliveries(sortDeliveries(deliveryData)));
    }
  }, [creatorId]);

  // Match route to find what we're editing
  const deliveryMatch = useMatch("/deliveries/:id");

  const [currentDelivery, setCurrentDelivery] = useState(null);
  if (
    deliveryMatch &&
    Number(deliveryMatch.params.id) !== currentDelivery?.deliveryId
  ) {
    setCurrentDelivery(
      deliveries.find(
        (delivery) => Number(deliveryMatch.params.id) === delivery.deliveryId
      )
    );
  }

  const match = useMatch("/metadata/:id");

  let currentFile = null;
  if (match && match !== "multiple") {
    const currentFileId = match.params.id;
    currentFile = files.find((file) => file.id === currentFileId);
  }

  const updateMetadata = (newFile) =>
    setFiles(files.map((file) => (file.id === newFile.id ? newFile : file)));

  const byId = (firstFile, secondFile) =>
    firstFile.id.localeCompare(secondFile.id);

  const setMultipleMetadata = (newFiles, apiMetadatas) => {
    const metadatas = apiMetadatas.map(metadataService.apiMetadataToMetadata);

    const storedFiles = newFiles.map((newFile, index) => ({
      ...newFile,
      metadata: metadatas[index],
      stored: true,
    }));

    /*
     * Replaces old files with new files by id.
     *
     * Sets files state:
     *  1. filter out files that are being replaced
     *  2. concat the replacing files
     *  3. sort the entire array.
     */
    setFiles(
      files
        .filter(
          (file) => !storedFiles.some((newFile) => newFile.id === file.id)
        )
        .concat(storedFiles)
        .sort(byId)
    );
  };
  const uncheckFiles = () =>
    setFiles(files.map((file) => ({ ...file, checked: false })));

  const handleLogout = async () => {
    await loginService.logout();
    logoutLocally();
  };

  const notify = (message, level) => {
    setMessage(message);
    setLevel(level);

    setTimeout(() => {
      setMessage("");
    }, 30000);
  };

  const updateLastModified = (deliveryId) =>
    setDeliveries(
      sortDeliveries(
        deliveries.map((delivery) =>
          delivery.deliveryId === deliveryId
            ? {
                ...delivery,
                lastModified: formatISO(new Date(), {
                  representation: "date",
                }),
              }
            : delivery
        )
      )
    );

  return (
    <div className="vh-100">
      <SpinnerComponent loading={loading} position="global" />
      <PortalHeader
        user={user}
        archiveName={archiveName}
        creatorName={creatorName}
        handleLogout={handleLogout}
      />
      <Container className="h-100">
        <Notification message={message} level={level} />
        <Routes>
          <Route
            path="/login"
            element={
              user ? (
                <Navigate to="/deliveries" />
              ) : (
                <Login setUser={setUser} notify={notify} />
              )
            }
          />
          <Route
            path="/password"
            element={
              user ? (
                <Navigate to="/deliveries" />
              ) : (
                <PasswordView notify={notify} />
              )
            }
          />
          <Route
            path="/metadata/multiple"
            element={
              user ? (
                <EditMultipleView
                  delivery={currentDelivery}
                  updateLastModified={updateLastModified}
                  files={files.filter((file) => file.checked)}
                  setMultipleMetadata={setMultipleMetadata}
                  user={user}
                  creator={{
                    name: creatorName,
                    number: creatorNumber,
                    id: creatorId,
                  }}
                  notify={notify}
                  toggleLoading={toggleLoading}
                  uncheckFiles={uncheckFiles}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/metadata/:id"
            element={
              user ? (
                <MetadataEntry
                  delivery={currentDelivery}
                  updateLastModified={updateLastModified}
                  file={currentFile}
                  files={files}
                  setCurrentMetadata={updateMetadata}
                  user={user}
                  creator={{
                    name: creatorName,
                    number: creatorNumber,
                    id: creatorId,
                  }}
                  toggleLoading={toggleLoading}
                  notify={notify}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/deliveries/:id"
            element={
              user ? (
                <FileUpload
                  delivery={currentDelivery}
                  updateLastModified={updateLastModified}
                  files={files}
                  setFiles={setFiles}
                  notify={notify}
                  creatorDesignation={`${country} ${archiveCode} ${creatorNumber}`}
                  user={user}
                  creator={{
                    name: creatorName,
                    number: creatorNumber,
                    id: creatorId,
                  }}
                  toggleLoading={toggleLoading}
                  setMultipleMetadata={setMultipleMetadata}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/deliveries"
            element={
              user ? (
                <DeliveryView
                  deliveries={deliveries}
                  setDeliveries={setDeliveries}
                  creators={creators}
                  setCurrentCreator={setCurrentCreator}
                  setFiles={setFiles}
                  notify={notify}
                  user={user}
                  creator={{
                    name: creatorName,
                    number: creatorNumber,
                    id: creatorId,
                  }}
                  toggleLoading={toggleLoading}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/admin/*"
            element={user ? <AdminRouter /> : <Navigate to="/login" />}
          />
          <Route
            path="/"
            element={
              user ? (
                <Navigate to="/deliveries" />
              ) : (
                <Login setUser={setUser} notify={notify} />
              )
            }
          />
        </Routes>
      </Container>
    </div>
  );
};

export default App;
