import React, { useState, useEffect, useCallback } from "react";

import userService from "../services/users";
import creatorService from "../services/creators";
import membershipService from "../services/memberships";

import UserView from "./UserView";
import CreatorView from "./CreatorView";
import AdminView from "./AdminView";

import { Routes, Route, Navigate } from "react-router-dom";

import "./AdminRouter.css";

const AdminRouter = () => {
  const [users, setUsers] = useState(null);
  const [creators, setCreators] = useState(null);
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    userService.getAll().then((userData) => setUsers(userData));
  }, []);

  useEffect(() => {
    creatorService.getAll().then((creatorData) => setCreators(creatorData));
  }, []);

  const handleMembershipsUpdate = useCallback(
    (creatorId) => {
      if (creatorId && creatorId !== "create") {
        membershipService
          .getAllByCreator(creatorId)
          .then((membershipData) => setMemberships(membershipData));
      }
    },
    [setMemberships]
  );

  const handleUserSubmit = (userId, alreadyStored, newUser, newMembership) => {
    if (newUser) {
      const newUsers = alreadyStored
        ? users.map((oldUser) =>
            String(oldUser.id) === userId ? newUser : oldUser
          )
        : users.concat(newUser);

      setUsers(newUsers);
    }

    if (newMembership) {
      const newMemberships = alreadyStored
        ? memberships.map((oldMembership) =>
            String(oldMembership.id) === newMembership.id
              ? // Spread oldMembership to retain name and ID
                { ...oldMembership, ...newMembership }
              : oldMembership
          )
        : memberships.concat(newMembership);

      setMemberships(newMemberships);
    }
  };

  const handleCreatorSubmit = (creatorId, alreadyStored, newCreator) => {
    const newCreators = alreadyStored
      ? creators.map((oldCreator) =>
          String(oldCreator.id) === creatorId ? newCreator : oldCreator
        )
      : creators.concat(newCreator);

    setCreators(newCreators);
  };

  return users && creators ? (
    <Routes>
      <Route path="users" element={<Navigate to=".." />} />
      <Route path="creators" element={<Navigate to=".." />} />
      <Route
        path="users/:userId/memberships/:membershipId/creators/:creatorId"
        element={<UserView users={users} onSubmit={handleUserSubmit} />}
      />
      <Route
        path="users/:userId"
        element={<UserView users={users} onSubmit={handleUserSubmit} />}
      />
      <Route
        path="creators/:id"
        element={
          <CreatorView
            creators={creators}
            onSubmit={handleCreatorSubmit}
            memberships={memberships}
            onMembershipsUpdate={handleMembershipsUpdate}
          />
        }
      />
      <Route
        path="/"
        element={<AdminView users={users} creators={creators} />}
      />
    </Routes>
  ) : (
    <p>Laddar ...</p>
  );
};

export default AdminRouter;
