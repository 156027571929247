import React, { useState } from "react";

import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";

import loginService from "../services/login";
import userService from "../services/users";

import { useNavigate } from "react-router-dom";

import "./Login.css";

import Button from "react-bootstrap/Button";

const Login = ({ setUser, notify }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    const newLogin = {
      email,
      password,
    };

    try {
      // This throws an exception if failing.
      // If succeeding, it sets a cookie that is implicitly used for
      // all following request.
      await loginService.login(newLogin);

      const user = await userService.getCurrent();

      window.localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
      navigate("/deliveries");
    } catch (error) {
      console.log(error);

      notify(
        "Inloggningen misslyckades. Kontrollera ditt användarnamn och lösenord.",
        "danger"
      );
      setEmail("");
      setPassword("");
    }
  };

  return (
    <div className="center">
      <h2>Välkommen till portalen för inleverans till arkivet!</h2>
      <Form onSubmit={handleLogin}>
        <Form.Group>
          <Form.Label htmlFor="inputEmail">E-post</Form.Label>
          <Form.Control
            type="email"
            name="email"
            id="inputEmail"
            className="form-control"
            placeholder="Email"
            required
            autoComplete="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="inputPassword">Lösenord</Form.Label>
          <Form.Control
            type="password"
            name="password"
            id="inputPassword"
            className="form-control"
            placeholder="Password"
            required
            autoComplete="current-password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </Form.Group>
        <div className="d-flex justify-content-between mt-2">
          <Button type="submit">Logga in</Button>
          <Link to="/password">Glömt lösenord?</Link>
        </div>
      </Form>
    </div>
  );
};

export default Login;
