import axios from "axios";

const getCurrent = async (userId, creatorId) => {
  const response = await axios.get("/api/signums", {
    params: { userId, creatorId },
  });
  const data = response.data;

  return {
    archiveCode: data.archiveCode,
    archiveName: data.archiveName,
    country: data.country,
    creatorName: data.creatorName,
    creatorNumber: data.creatorNr,
    creatorId: data.creatorId,
    userFirstName: data.firstName,
    userLastName: data.lastName,
  };
};

const archiveService = { getCurrent };
export default archiveService;
