import { useState } from "react";

import AdminTable from "./AdminTable";

import Form from "react-bootstrap/Form";

const AdminView = ({ users, creators }) => {
  const [selection, setSelection] = useState(null);

  return (
    <div>
      <h2>
        Administrera {selection === "creators" ? "arkivbildare" : "användare"}
      </h2>
      <div className="d-flex">
        <Form className="vh-100 border-right">
          <Form.Select
            id="admin-select"
            onChange={({ target }) => setSelection(target.value)}
          >
            <option value="users">Användare</option>
            <option value="creators">Arkivbildare</option>
          </Form.Select>
        </Form>
        <AdminTable selection={selection} users={users} creators={creators} />
      </div>
    </div>
  );
};

export default AdminView;
