import Form from "react-bootstrap/Form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { fields } from "../services/options";

const MetadataInput = ({
  id,
  required,
  elementId,
  value,
  setValue,
  isColumnEdit,
}) => {
  const field = fields[id];
  const multiple = field.multiple;
  const options = field.options;
  const disabled = field.disabled;
  const name = field.name;

  const ariaLabel = isColumnEdit
    ? `Redigera hela kolumnen ${name.toLowerCase()}`
    : name;

  const commonProps = {
    placeholder: "",
    noOptionsMessage: () => "Inga alternativ",
    formatCreateLabel: (value) => `Skapa ${value}`,
    isMulti: true,
    styles: {
      container: (provided) => ({
        ...provided,
        minWidth: "150px",
      }),
    },
    "aria-label": ariaLabel,
  };

  return multiple ? (
    options ? (
      <Select
        inputId={elementId}
        value={value}
        onChange={setValue}
        required={required}
        {...commonProps}
        options={options}
      />
    ) : (
      <CreatableSelect
        inputId={elementId}
        value={value}
        onChange={setValue}
        required={required}
        {...commonProps}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    )
  ) : options ? (
    <Select
      inputId={elementId}
      value={value}
      onChange={setValue}
      required={required}
      {...{ ...commonProps, isMulti: false }}
      options={options}
    />
  ) : (
    <Form.Control
      id={elementId}
      value={value}
      onChange={({ target }) => setValue(target.value)}
      required={required}
      aria-label={ariaLabel}
      disabled={disabled}
    />
  );
};

export default MetadataInput;
