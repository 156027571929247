import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { Link, useNavigate } from "react-router-dom";

const AdminTable = ({ selection, users, creators }) => {
  const navigate = useNavigate();

  return (
    <div id="file-table" className="table-responsive ">
      <Table striped>
        <thead>
          <tr>
            <td>{selection === "creators" ? "Arkivbildarnr" : "Användarnr"}</td>
            <td>Namn</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {selection === "creators"
            ? creators.map((creator) => (
                <tr key={creator.id}>
                  <td>{creator.archiveCreatorNr}</td>
                  <td>{creator.associationName}</td>
                  <td>
                    <Button
                      id={creator.id}
                      type="button"
                      onClick={() => navigate(`/admin/creators/${creator.id}`)}
                    >
                      Redigera
                    </Button>
                  </td>
                </tr>
              ))
            : users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>
                    <Button
                      id={user.id}
                      type="button"
                      onClick={() => navigate(`/admin/users/${user.id}`)}
                    >
                      Redigera
                    </Button>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      {selection === "creators" && (
        <Link to="/admin/creators/create">
          <Button className="float-right">Lägg till ny arkivbildare</Button>
        </Link>
      )}
    </div>
  );
};

export default AdminTable;
