import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PlusLg } from "react-bootstrap-icons";

import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import deliveryService, { sortDeliveries } from "../services/deliveries";

const DeliveryView = ({
  deliveries,
  setDeliveries,
  creators,
  setCurrentCreator,
  setFiles,
  notify,
  user,
  creator,
  toggleLoading,
}) => {
  const [selectedRadio, setSelectedRadio] = useState("Alla");
  const navigate = useNavigate();

  const handleRadioChange = ({ target }) => setSelectedRadio(target.id);

  const handleNewDelivery = async () => {
    const delivery = await deliveryService.create(user.id, creator.id);
    setDeliveries(sortDeliveries(deliveries.concat(delivery)));
    setFiles(delivery.files);
    navigate(`/deliveries/${delivery.deliveryId}`);
  };

  const handleEditDelivery = async ({ target }) => {
    const delivery = await deliveryService.getOne(target.id);
    setFiles(delivery.files);
    navigate(`/deliveries/${delivery.deliveryId}`);
  };

  const handleCheckAll = ({ target }) => {
    setDeliveries(
      deliveries.map((delivery) => ({ ...delivery, checked: target.checked }))
    );
  };

  const handleCheck = ({ target }) => {
    setDeliveries(
      deliveries.map((delivery) =>
        delivery.deliveryId.toString() === target.className
          ? { ...delivery, checked: target.checked }
          : delivery
      )
    );
  };

  const handleCreatorChange = ({ target }) => {
    setCurrentCreator(
      creators.find((creator) => String(creator.creatorId) === target.value)
    );
  };

  const handleSend = async (status) => {
    const checkedDeliveries = deliveries.filter((delivery) => delivery.checked);

    const recipient =
      status === "För godkännande" ? "arkivarien" : "arkivbildaren";
    const message = window.prompt(`Meddelande till ${recipient}`);

    try {
      toggleLoading(true);

      // Rejects if any rejects
      // May leave React state inconsistent with database
      await Promise.all(
        checkedDeliveries.map(async (delivery) =>
          deliveryService.setStatus(delivery.deliveryId, status, message)
        )
      );

      // Otherwise, all of them are successful
      const checkedSuccessfulDeliveries = checkedDeliveries.map((delivery) => ({
        ...delivery,
        status,
        checked: false,
      }));

      setDeliveries(
        sortDeliveries(
          deliveries
            .filter((delivery) => !delivery.checked)
            .concat(checkedSuccessfulDeliveries)
        )
      );
    } catch (error) {
      notify("Det gick inte att lämna in leveranserna för godkännande.");
    } finally {
      toggleLoading(false);
    }
  };

  const loggedInAsAdmin = user?.roles?.includes("ROLE_ADMIN");

  const isEditDisabled = (delivery) => {
    if (loggedInAsAdmin) {
      return delivery.status !== "För godkännande";
    } else {
      return delivery.status !== "Pågående";
    }
  };

  return (
    <>
      {user && creators.length ? (
        <div>
          <h2>Inleveranser</h2>
          <div className="d-flex justify-content-between mb-3">
            <Form>
              <Form.Check
                inline
                label="Pågående"
                name="status"
                type="radio"
                id="Pågående"
                onChange={handleRadioChange}
                checked={selectedRadio === "Pågående"}
              />
              <Form.Check
                inline
                label="För godkännande"
                name="status"
                type="radio"
                id="För godkännande"
                onChange={handleRadioChange}
                checked={selectedRadio === "För godkännande"}
              />
              <Form.Check
                inline
                label="Inskickat"
                name="status"
                type="radio"
                id="Inskickat"
                onChange={handleRadioChange}
                checked={selectedRadio === "Inskickat"}
              />
              <Form.Check
                inline
                label="Alla"
                name="status"
                type="radio"
                id="Alla"
                onChange={handleRadioChange}
                checked={selectedRadio === "Alla"}
              />
            </Form>
            <select
              name="creator"
              onChange={handleCreatorChange}
              value={creator.id}
            >
              {creators.map((creator) => (
                <option key={creator.creatorId} value={creator.creatorId}>
                  {creator.creatorName} ({creator.creatorNumber})
                </option>
              ))}
            </select>
            {!loggedInAsAdmin && (
              <Button type="button" onClick={handleNewDelivery}>
                <PlusLg /> Lägg till ny inleverans
              </Button>
            )}
          </div>
          <div id="file-table" className="table-responsive">
            <Table striped>
              <thead>
                <tr>
                  <td>
                    <input type="checkbox" onChange={handleCheckAll} />
                  </td>
                  <td>Diarieår</td>
                  <td>Inleveransnr</td>
                  <td>Startdatum</td>
                  <td>Senast ändrad</td>
                  <td>Slutdatum</td>
                  <td>Inlagd av</td>
                  <td>Status</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {deliveries
                  .filter(
                    (delivery) =>
                      delivery.status === selectedRadio ||
                      selectedRadio === "Alla"
                  )
                  .map((delivery, index) => (
                    <tr key={delivery.deliveryId}>
                      <td>
                        <input
                          type="checkbox"
                          className={`${delivery.deliveryId}`}
                          checked={!!deliveries[index].checked}
                          onChange={handleCheck}
                        />
                      </td>
                      <td>{delivery.diaryYear}</td>
                      <td>{delivery.deliveryId}</td>
                      <td>{delivery.startDate}</td>
                      <td>{delivery.lastModified}</td>
                      <td>{delivery.endDate}</td>
                      <td>{delivery.addedBy}</td>
                      <td>{delivery.status}</td>
                      <td>
                        <Button
                          id={delivery.deliveryId}
                          type="button"
                          onClick={handleEditDelivery}
                          disabled={isEditDisabled(delivery)}
                        >
                          Arbeta med inleverans
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-end mt-2">
            {loggedInAsAdmin ? (
              <>
                <Button type="button" onClick={() => handleSend("Pågående")}>
                  Ändra status
                </Button>
                <Button
                  className="mx-2"
                  type="button"
                  onClick={() => handleSend("Inskickat")}
                >
                  Skicka
                </Button>
              </>
            ) : (
              <Button
                type="button"
                onClick={() => handleSend("För godkännande")}
              >
                För godkännande
              </Button>
            )}
          </div>
        </div>
      ) : (
        <p>
          Dina arkivbildare laddas. Om du inte är medlem i någon arkivbildare,
          kontakta arkivet.
        </p>
      )}
    </>
  );
};

export default DeliveryView;
