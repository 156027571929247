import Form from "react-bootstrap/Form";
import InfoIcon from "./InfoIcon";

import { fields } from "../services/options";

import "./MetadataLabel.css";

const MetadataLabel = ({ id, required, htmlFor }) => {
  const field = fields[id];

  return (
    <div className="metadata-label">
      <Form.Label htmlFor={htmlFor}>
        {field.name} {required && <span id="asterisk">*</span>}
      </Form.Label>
      <InfoIcon description={field.description} />
    </div>
  );
};

export default MetadataLabel;
