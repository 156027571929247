import axios from "axios";
import { parseISO, formatISO } from "date-fns";
import metadataService from "./metadata";

import _ from "lodash";

const baseUrl = "/api/deliveries";

const create = async (userId, creatorId) => {
  const response = await axios.post(baseUrl, null, {
    params: { userId, creatorId },
  });
  return apiDeliveryToObject(response.data);
};

const humanReadableDate = (isoString) => {
  try {
    return formatISO(parseISO(isoString), { representation: "date" });
  } catch (e) {
    return null;
  }
};

const apiStatusToStatus = (apiStatus) => {
  switch (apiStatus) {
    case "ONGOING":
      return "Pågående";
    case "ACCEPTANCE":
      return "För godkännande";
    case "FINISHED":
      return "Inskickat";
    default:
      return apiStatus;
  }
};

const statusToApiStatus = (status) => {
  switch (status) {
    case "Pågående":
      return "ONGOING";
    case "För godkännande":
      return "ACCEPTANCE";
    case "Inskickat":
      return "FINISHED";
    default:
      return status;
  }
};

const apiFileToFile = (apiFile) => ({
  file: null,
  id: apiFile.id.toString(),
  name: apiFile.fileName.split("objects/").pop(),
  checked: false,
  stored: true,
  metadata: metadataService.apiMetadataToMetadata(apiFile.metadata),
});

export const apiDeliveryToObject = (apiDelivery) => ({
  creatorId: apiDelivery.creatorId,
  deliveryId: apiDelivery.deliveryId,
  diaryYear: apiDelivery.diaryYear,
  startDate: humanReadableDate(
    apiDelivery.deliveryDate ? apiDelivery.deliveryDate : apiDelivery.startDate
  ),
  endDate: humanReadableDate(apiDelivery.endDate),
  status: apiStatusToStatus(apiDelivery.deliveryStatus ?? apiDelivery.status),
  addedBy: apiDelivery.addedBy,
  lastModified: humanReadableDate(apiDelivery.lastModified),
  files: apiDelivery.files?.map(apiFileToFile),
});

const getOne = async (id) => {
  const response = await axios.get(`${baseUrl}/contents`, {
    params: { id },
  });

  return apiDeliveryToObject(response.data);
};

const getAll = async (creatorId) => {
  const response = await axios.get(baseUrl, {
    params: { id: creatorId },
  });

  return response.data.map(apiDeliveryToObject);
};

const setStatus = async (deliveryId, status, message) => {
  const response = await axios.patch(
    baseUrl,
    JSON.stringify({ message }, null, 1),
    {
      headers: { "Content-Type": "application/json" },
      params: { id: deliveryId, status: statusToApiStatus(status) },
    }
  );
  return apiStatusToStatus(response.status);
};

export const sortDeliveries = (deliveries) =>
  _.orderBy(deliveries, ["lastModified", "deliveryId"], ["desc", "desc"]);

const deliveryService = { create, getOne, getAll, setStatus };
export default deliveryService;
