import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import fileService from "../services/files";
import metadataService from "../services/metadata";

import MetadataFormGroup from "./MetadataFormGroup";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

const MetadataEntry = ({
  delivery,
  updateLastModified,
  file,
  files,
  setCurrentMetadata,
  user,
  creator,
  toggleLoading,
  notify,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [format, setFormat] = useState("");
  const [source, setSource] = useState("");
  const [language, setLanguage] = useState("");

  const [rights, setRights] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [dates, setDates] = useState([]);
  const [types, setTypes] = useState([]);
  const [coverages, setCoverages] = useState([]);

  const [creators, setCreators] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [contributors, setContributors] = useState([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentId = file.id;
  const currentIndex = files.findIndex((file) => file.id === currentId);
  const previousFile = files[currentIndex - 1];
  const nextFile = files[currentIndex + 1];

  useEffect(() => {
    const loadMetadata = ({ metadata }) => {
      if (metadata) {
        setTitle(metadata.title);
        setDescription(metadata.description);
        setFormat(metadata.format);
        setSource(metadata.source);
        setLanguage(metadata.language);

        setRights(metadataService.stringToSelect(metadata.rights));
        setSubjects(metadata.subjects.map(metadataService.stringToSelect));
        setDates(metadata.dates.map(metadataService.stringToSelect));
        setTypes(metadata.types.map(metadataService.stringToSelect));
        setCoverages(metadata.coverages.map(metadataService.stringToSelect));

        setCreators(
          metadata.creators.length
            ? metadata.creators.map(metadataService.stringToSelect)
            : [creator.name].map(metadataService.stringToSelect)
        );
        setPublishers(metadata.publishers.map(metadataService.stringToSelect));
        setContributors(
          metadata.contributors.map(metadataService.stringToSelect)
        );
      }
    };

    loadMetadata(file);
  }, [file, creator.name]);

  useEffect(() => {
    // Scroll to top on render
    window.scrollTo(0, 0);
  }, [pathname]);

  const clearMetadata = () => {
    setTitle("");
    setDescription("");
    setFormat("");
    setSource("");
    setLanguage("");

    setRights({});
    setSubjects([]);
    setDates([]);
    setTypes([]);
    setCoverages([]);

    setCreators([]);
    setPublishers([]);
    setContributors([]);
  };

  const saveMetadata = async () => {
    try {
      toggleLoading(true);

      const selectToString = (select) => select.value;
      const newFile = {
        ...file,
        metadata: {
          ...file.metadata,
          title,
          description,
          format,
          source,
          language,
          rights: selectToString(rights),
          subjects: subjects.map(selectToString),
          dates: dates.map(selectToString),
          types: types.map(selectToString),
          coverages: coverages.map(selectToString),
          creators: creators.map(selectToString),
          publishers: publishers.map(selectToString),
          contributors: contributors.map(selectToString),
        },
      };

      let apiMetadata = {};
      if (file.stored) {
        apiMetadata = (
          await fileService.update(delivery.deliveryId, [newFile])
        )[0];
      } else {
        apiMetadata = fileService.injectId(
          await fileService.create(
            delivery.deliveryId,
            [newFile],
            user.id,
            creator.id
          )[0]
        );
      }
      const metadata = metadataService.apiMetadataToMetadata(apiMetadata);

      setCurrentMetadata({ ...newFile, metadata, stored: true });
      updateLastModified(delivery.deliveryId);
      notify("Dina ändringar är sparade.", "info");
    } catch (e) {
      notify("Något gick fel.");
    } finally {
      toggleLoading(false);
    }
  };

  const handleBack = async (event) => {
    if (
      window.confirm(
        "Är du säker på att du vill lämna sidan? Osparade ändringar kommer gå förlorade."
      )
    ) {
      clearMetadata();
      navigate(`/deliveries/${delivery.deliveryId}`);
    }
  };

  const handleNewMetadata = async (event) => {
    const form = event.target.parentNode.parentNode.parentNode;

    // Handle react-select form validity manually
    if (!dates.length) {
      document
        .getElementById("dates")
        .setCustomValidity("Fyll i det här fältet.");
    } else {
      document.getElementById("dates").setCustomValidity("");
    }

    if (form.reportValidity()) {
      await saveMetadata();
    }
  };

  const handleNext = async (event) => {
    event.preventDefault();

    await saveMetadata();
    clearMetadata();
    if (nextFile) {
      navigate(`/metadata/${nextFile.id}`);
    } else {
      navigate(`/deliveries/${delivery.deliveryId}`);
    }
  };

  const handlePrevious = async (event) => {
    const form = event.target.parentNode.parentNode.parentNode;
    if (previousFile && form.reportValidity()) {
      await saveMetadata();
      clearMetadata();
      navigate(`/metadata/${previousFile.id}`);
    }
  };

  const loggedInAsAdmin = user?.roles?.includes("ROLE_ADMIN");

  return (
    <Container>
      <h2>Metadata för filen: {file.name}</h2>
      <Form aria-label="form" onSubmit={handleNext}>
        {loggedInAsAdmin && (
          <MetadataFormGroup id="format" value={format} setValue={setFormat} />
        )}
        <MetadataFormGroup
          id="title"
          value={title}
          setValue={setTitle}
          required={true}
        />
        <MetadataFormGroup
          id="description"
          value={description}
          setValue={setDescription}
        />
        <MetadataFormGroup
          id="subjects"
          value={subjects}
          setValue={setSubjects}
        />
        <MetadataFormGroup
          id="dates"
          value={dates}
          setValue={setDates}
          required={true}
        />
        <MetadataFormGroup
          id="rights"
          value={rights}
          setValue={setRights}
          required={true}
        />
        <MetadataFormGroup
          id="language"
          value={language}
          setValue={setLanguage}
        />
        <MetadataFormGroup
          id="creators"
          value={creators}
          setValue={setCreators}
        />
        <MetadataFormGroup
          id="contributors"
          value={contributors}
          setValue={setContributors}
        />
        <MetadataFormGroup id="source" value={source} setValue={setSource} />
        <MetadataFormGroup
          id="coverages"
          value={coverages}
          setValue={setCoverages}
        />
        <MetadataFormGroup
          id="publishers"
          value={publishers}
          setValue={setPublishers}
        />
        <Row className="w-100 justify-content-between my-2">
          <div>
            <Button
              className="mx-2"
              type="button"
              disabled={!previousFile}
              onClick={handlePrevious}
            >
              Föregående
            </Button>
          </div>
          <div>
            <Button
              className="mx-2"
              type="button"
              variant="secondary"
              onClick={handleBack}
            >
              Tillbaka
            </Button>
            <Button
              className="ml-2 mr-4"
              type="button"
              onClick={handleNewMetadata}
            >
              Spara
            </Button>
            <Button className="mx-2" type="submit" disabled={!nextFile}>
              Nästa
            </Button>
          </div>
        </Row>
      </Form>
    </Container>
  );
};

export default MetadataEntry;
