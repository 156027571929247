import axios from "axios";
import qs from "qs";

const baseUrl = "/api/login";

const login = async (newLogin) => {
  const response = await axios.post(baseUrl, qs.stringify(newLogin));

  return response.data;
};

/**
 * Sends a logout request. The server response includes a 'Set-Cookie' to delete
 * the current cookie. It cannot be done in JS because the cookie is HttpOnly.
 */
const logout = async () => {
  const response = await axios.post("/api/logout");

  return response.data;
};

const requestPassword = async (email) => {
  const response = await axios.post("/api/users", null, { params: { email } });

  return response.data;
};

const loginService = { login, logout, requestPassword };
export default loginService;
