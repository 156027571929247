import Form from "react-bootstrap/Form";
import MetadataLabel from "./MetadataLabel";
import MetadataInput from "./MetadataInput";

const MetadataFormGroup = ({ id, required, value, setValue }) => {
  return (
    <Form.Group id={`div-${id}`} className="justify-content-between">
      <MetadataLabel id={id} required={required} htmlFor={id} />
      <MetadataInput
        id={id}
        required={required}
        elementId={id}
        value={value}
        setValue={setValue}
      />
    </Form.Group>
  );
};

export default MetadataFormGroup;
