import Alert from "react-bootstrap/Alert";
import { InfoCircleFill, ExclamationCircleFill } from "react-bootstrap-icons";

const Notification = ({ message, level }) => {
  const variant = level === "info" ? "info" : "danger";

  return message ? (
    <Alert variant={variant}>
      {variant === "info" ? (
        <InfoCircleFill className="mr-3" />
      ) : (
        <ExclamationCircleFill className="mr-3" />
      )}
      {message}
    </Alert>
  ) : null;
};

export default Notification;
